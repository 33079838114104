
import { Options, Vue } from "vue-class-component";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/form/MButton.vue";
import MTextArea from "@/components/form/MTextArea.vue";

@Options({
  components: {
    MBaseModal,
    MButton,
    MTextArea
  },
  props: {
    title: String,
    description: String,
    defaultValue: String
  },
  emits: ["close", "save", "input"]
})
export default class MTextInputModal extends Vue {
  defaultValue = "";
  value = "";
  title = "テキストを入力";
  description = "";

  close() {
    this.$emit("close");
  }
  input(next: string) {
    this.$emit("input", next);
  }
  save() {
    this.close();
    this.$emit("save", this.value);
  }

  created() {
    this.value = this.defaultValue;
  }
}
