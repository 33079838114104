
import firebase from "firebase/app";
import "firebase/storage";
import MButton from "@/components/form/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MTextInputModal from "@/components/MTextInputModal.vue";
import {
  getSubmissionOfStudent,
  putSubmission,
  createNotificationForTutors,
  complementSubmissions
} from "@/api/submission";
import { postFile, deleteFile, postFileAsUploadTask } from "@/api/storage";
import { Student } from "@/entities/student";
import {
  safeFileTypes,
  SubmissionStatus,
  getValuesFromFileMetadata,
  ComplementedSubmission
} from "@/entities/submission";
import { RelatedLink } from "@/entities/submission_master";
import store, { awaitStudentLoaded } from "@/store";
import {
  convertToDateFromUnixtime,
  convertToTimeFromUnixtime
} from "@/utils/date";
import { Options, Vue } from "vue-class-component";
import dayjs from "dayjs";
import Compressor from "compressorjs";
import mime from "mime";

@Options({
  components: {
    MButton,
    MIcon,
    MTextInputModal
  }
})
export default class SubmissionView extends Vue {
  checkedAtText = "";
  submittedAtText = "";
  dataMap: ComplementedSubmission | null = null;
  imageUrls: string[] = [];
  imageUrlsRequiringAltDisplay: string[] = []; // Storage上に画像がない場合 URL をこちらに格納し、代替表示を行う
  fileDataList: {
    url: string;
    contentTypeToDisplay: string;
    contentIconType: string;
    sizeString: string;
  }[] = [];
  status: SubmissionStatus = "unsubmitted";
  closedAtText = "";
  submissionName = "";
  submissionLabel = "";
  description = "";
  comment = "";
  relatedLinks: RelatedLink[] = [];
  student: Student | null = null;
  showCommentModal = false;

  get isAndroid() {
    // 撮影ボタンを Android のみ表示する
    return store.state.isAndroid;
  }

  get validData() {
    return this.imageUrls.length > 0 || this.fileDataList.length > 0;
  }

  get hasPreviousPage() {
    // 通知メッセージから直接この画面を開いた場合、戻り先のページがない
    return history.length > 1;
  }

  async imageUpload(event: Event) {
    if (
      !(event.target instanceof HTMLInputElement) ||
      !event.target.files ||
      event.target.files.length === 0 ||
      !store.state.schoolDocId ||
      !store.state.classroomDocId ||
      !this.student ||
      !this.dataMap
    ) {
      return;
    }
    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "画像をアップロード中...");

    const submittedAt = dayjs().format("YYYY-MM-DD-HH-mm-ss");
    const promises: Promise<string>[] = [];

    for (let i = 0, l = event.target.files.length; i < l; i++) {
      const file = event.target.files[i];
      if (!safeFileTypes.includes(file.type)) {
        store.commit("SET_LOADING", false);
        alert(`次の種類のファイルは提出できません。\n【${file.type}】`);
        continue;
      }
      const directory = `submissions/schools/${store.state.schoolDocId}/classrooms/${store.state.classroomDocId}/students/${this.student.ref.id}/submissions/${this.dataMap.ref.id}/`;
      const fileName = `${this.dataMap.submissionMasterRef?.id ?? ""}_${
        this.dataMap.ref.id
      }_${submittedAt}_${i}`;

      // 軽量化してアップロードする
      promises.push(
        new Promise((resolve, reject) => {
          new Compressor(file, {
            quality: 0.5,
            convertSize: 300 * 1000, // 300KB 以上の png は jpg に変換する
            success(result) {
              const fileToUpload = result as File;
              // jpg に変換されている可能性があるので、ここで拡張子を決定する
              const ext = "." + fileToUpload.name.split(".").pop();

              postFile(fileToUpload, {
                path: directory + fileName + ext
              })
                .then(({ downloadURL }: { downloadURL: string }) => {
                  resolve(downloadURL);
                })
                .catch(reject);
            },
            error: reject
          });
        })
      );
    }
    await Promise.all(promises)
      .then(newUrls => {
        if (!this.dataMap) throw new Error("非同期処理中の例外的なエラー"); // 通常考えられない、ビルドを通すために必要
        putSubmission(this.dataMap.ref, {
          imageUrls: [...this.imageUrls, ...newUrls]
        });
        this.imageUrls = [...this.imageUrls, ...newUrls];
      })
      .catch(e => {
        alert(`ファイルの提出時にエラーが発生しました。\n\n${e.message}`);
      });
    store.commit("SET_LOADING", false);
  }

  async freeFormatFileUpload(event: Event) {
    if (
      !(event.target instanceof HTMLInputElement) ||
      !event.target.files ||
      event.target.files.length === 0 ||
      !store.state.schoolDocId ||
      !store.state.classroomDocId ||
      !this.student ||
      !this.dataMap
    ) {
      return;
    }
    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "ファイルをアップロード中...");

    const submittedAt = dayjs().format("YYYY-MM-DD-HH-mm-ss");

    const file = event.target.files[0];
    const directory = `submissions/schools/${store.state.schoolDocId}/classrooms/${store.state.classroomDocId}/students/${this.student.ref.id}/submissions/${this.dataMap.ref.id}/`;
    const fileName = `${this.dataMap.submissionMasterRef?.id}_${this.dataMap.ref.id}_file_${submittedAt}`;

    new Promise<firebase.storage.Reference>((resolve, reject) => {
      const ext = mime.getExtension(file.type)
        ? `.${mime.getExtension(file.type)}`
        : "";
      postFileAsUploadTask(file, {
        path: `${directory}${fileName}${ext}`,
        onStateChanged: summary => {
          store.commit(
            "SET_LOAD_TEXT",
            `アップロード中 : ${summary.transferredPercentage} / 100 % 完了`
          );
        },
        onError: reject,
        onComplete: resolve
      });
    })
      .then(async fileRef => {
        const downloadURL = await fileRef.getDownloadURL();
        const { contentType, size } = await fileRef.getMetadata();
        if (!this.dataMap) throw new Error("非同期処理中の例外的なエラー"); // 通常考えられない、ビルドを通すために必要
        putSubmission(this.dataMap.ref, {
          fileUrls: [...this.fileDataList.map(data => data.url), downloadURL]
        });
        const values = getValuesFromFileMetadata(size, contentType);
        this.fileDataList = [
          ...this.fileDataList,
          {
            url: downloadURL,
            ...values,
            contentTypeToDisplay:
              values.contentIconType !== "file"
                ? values.contentTypeToDisplay
                : `${mime.getExtension(contentType)}ファイル` ??
                  values.contentTypeToDisplay
          }
        ];
        store.commit("SET_LOADING", false);
        store.commit("SET_LOAD_TEXT", "");
      })
      .catch(e => {
        alert(`ファイルの提出時にエラーが発生しました。\n\n${e.message}`);
        store.commit("SET_LOADING", false);
        store.commit("SET_LOAD_TEXT", "");
      });
  }

  async deleteImage(url: string) {
    if (!this.dataMap) return;
    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "画像を削除中...");
    const imageRef = firebase.storage().refFromURL(url);
    try {
      const newUrls = this.imageUrls.filter(u => u !== url);
      putSubmission(this.dataMap.ref, {
        imageUrls: newUrls
      });
      this.imageUrls = newUrls;
      if (!this.imageUrlsRequiringAltDisplay.includes(url))
        await deleteFile(imageRef);
    } catch (e) {
      alert(`画像の削除に失敗しました。\n\n${e.message}`);
    } finally {
      store.commit("SET_LOADING", false);
    }
  }

  async deleteFreeFormatFile(url: string) {
    if (!this.dataMap) return;
    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "ファイルを削除中...");
    const fileRef = firebase.storage().refFromURL(url);
    try {
      const newDataList = this.fileDataList.filter(data => data.url !== url);
      putSubmission(this.dataMap.ref, {
        fileUrls: [...newDataList.map(data => data.url)]
      });
      this.fileDataList = newDataList;
      await deleteFile(fileRef);
    } catch (e) {
      alert(`ファイルの削除に失敗しました。\n\n${e.message}`);
    } finally {
      store.commit("SET_LOADING", false);
    }
  }

  setAltImage(url: string) {
    this.imageUrlsRequiringAltDisplay = [
      url,
      ...this.imageUrlsRequiringAltDisplay
    ];
  }

  async completeSubmission() {
    if (
      !this.validData ||
      !this.student ||
      !this.dataMap ||
      this.status === "submitted" ||
      this.status === "rejected_submitted" ||
      this.status === "fulfilled"
    )
      return;
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "先生にメッセージを送信中...");
      await putSubmission(this.dataMap.ref, {
        status:
          this.status === "unsubmitted" ? "submitted" : "rejected_submitted",
        submittedAt: dayjs().unix()
      });
      await createNotificationForTutors(this.student.ref, {
        title: `${this.student.data.name}が${this.submissionName}を${
          this.status === "unsubmitted" ? "" : "再"
        }提出しました`,
        content: this.comment ? `【先生へのコメント】\n\n${this.comment}` : "",
        submissionDocId: this.dataMap.ref.id
      });
      alert(
        `🙆‍♂️ ${this.submissionName}を提出したことを先生に報告しました📣 おつかれさま😀`
      );
      this.$router.replace({
        path: "/submission/"
      });
    } catch (e) {
      alert(`提出完了の処理中にエラーが発生しました。`);
    } finally {
      store.commit("SET_LOADING", false);
    }
  }

  goBack() {
    this.$router.go(-1);
  }

  openCommentModal() {
    this.showCommentModal = true;
  }

  updateComment(next: string) {
    this.comment = next;
  }

  async saveComment(next: string) {
    if (!this.dataMap) return;
    // 期限なしの場合、post の一種類として先生へのコメントを考える
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "コメントを保存中...");
      await putSubmission(this.dataMap.ref, {
        comment: next
      });
      this.comment = next;
    } catch (e) {
      alert(`先生へのコメントが正しく保存できませんでした。`);
    } finally {
      store.commit("SET_LOADING", false);
    }
  }

  async created() {
    this.student = await awaitStudentLoaded(store);
    const submissionId = this.$route.params.submissionId as string;
    if (!submissionId) {
      return;
    }
    const submissions = await getSubmissionOfStudent(this.student.ref, {
      submissionId
    });
    if (!submissions?.[0]) return;
    const complemented = (await complementSubmissions(
      submissions
    )) as ComplementedSubmission[];
    this.dataMap = complemented[0];
    if (!this.dataMap?.submissionMasterData) return;

    this.imageUrls = this.dataMap.data.imageUrls;
    this.comment = this.dataMap.data.comment ?? "";
    this.fileDataList = this.dataMap.data.fileDataList;
    this.status = this.dataMap.data.status;
    this.checkedAtText =
      this.dataMap.data.checkedAt > 0
        ? convertToDateFromUnixtime(this.dataMap.data.checkedAt)
        : "";
    this.submittedAtText =
      this.dataMap.data.submittedAt > 0
        ? convertToDateFromUnixtime(this.dataMap.data.submittedAt)
        : "";
    this.closedAtText =
      this.dataMap.data.closedAt > 0
        ? `${convertToDateFromUnixtime(
            this.dataMap.data.closedAt
          )} ${convertToTimeFromUnixtime(this.dataMap.data.closedAt)}`
        : "";
    this.submissionName = this.dataMap.submissionMasterData.name;
    this.submissionLabel = this.dataMap.submissionMasterData.label;
    this.description = this.dataMap.submissionMasterData.description ?? "";
    this.relatedLinks = this.dataMap.submissionMasterData.relatedLinks ?? [];
  }
}
