<template>
  <MBaseModal @close="close">
    <template #title>{{ title }}</template>
    <template #body>
      <div
        class="w-full h-full flex flex-col items-center justify-center text-center bg-primary-100"
      >
        <div v-if="description" class="mb-4">{{ description }}</div>
        <div class="w-full px-4">
          <textarea
            @input="e => input(e.target.value)"
            v-model="value"
            class="w-full bg-white rounded-md border border-white focus:border-primary-500 text-base outline-none appearance-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-300"
          />
        </div>
        <MButton @click="save">保存</MButton>
      </div>
    </template>
  </MBaseModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/form/MButton.vue";
import MTextArea from "@/components/form/MTextArea.vue";

@Options({
  components: {
    MBaseModal,
    MButton,
    MTextArea
  },
  props: {
    title: String,
    description: String,
    defaultValue: String
  },
  emits: ["close", "save", "input"]
})
export default class MTextInputModal extends Vue {
  defaultValue = "";
  value = "";
  title = "テキストを入力";
  description = "";

  close() {
    this.$emit("close");
  }
  input(next: string) {
    this.$emit("input", next);
  }
  save() {
    this.close();
    this.$emit("save", this.value);
  }

  created() {
    this.value = this.defaultValue;
  }
}
</script>
