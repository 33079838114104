<template>
  <div class="flex">
    <main class="container mx-auto flex flex-col items-center mt-10 px-4 pb-60">
      <h2 class="text-2xl font-bold mb-10">
        {{ submissionName
        }}{{ status === "unsubmitted" ? "を提出" : "の提出済の内容" }}
      </h2>
      <section class="flex flex-col w-full mb-6">
        <h3 class="mb-2 font-medium">提出期限</h3>
        <p class="w-full p-3 rounded-md bg-white text-sm">{{ closedAtText }}</p>
      </section>
      <section v-if="description" class="flex flex-col w-full mb-6">
        <h3 class="mb-2 font-medium">先生から</h3>
        <p class="w-full p-3 rounded-md bg-white text-sm">{{ description }}</p>
      </section>
      <section v-if="relatedLinks.length > 0" class="flex flex-col w-full mb-6">
        <h3 class="mb-2 font-medium">参考リンク/資料</h3>
        <div class="w-full p-3 rounded-md bg-white text-sm">
          <a
            v-for="(link, i) in relatedLinks"
            :key="`link${i}`"
            :href="link.url"
            target="_blank"
            rel="noopener noreferrer"
            class="underline text-blue-500 mr-4 break-all"
            >{{ link.label }}</a
          >
        </div>
      </section>
      <section class="flex flex-col w-full mb-6">
        <h3 class="mb-2 font-medium">先生へのコメント</h3>
        <p
          v-if="comment"
          class="w-full p-3 rounded-md bg-white text-sm whitespace-pre-wrap"
        >
          {{ comment }}
        </p>
        <p v-else class="w-full p-3 rounded-md bg-white text-gray-500 text-sm">
          先生へのコメントはありません
        </p>
      </section>
      <section class="flex flex-col w-full mb-6">
        <h3 class="mb-2 font-medium">画像（写真）</h3>
        <p
          v-if="imageUrls.length === 0"
          class="w-full p-3 rounded-md bg-white text-gray-500 text-sm"
        >
          画像はありません
        </p>
        <div
          v-for="(url, i) in imageUrls"
          :key="`image${i}`"
          class="flex-shrink-0 flex justify-center relative"
        >
          <img
            v-if="!imageUrlsRequiringAltDisplay.includes(url)"
            :src="url"
            alt="提出画像"
            class="h-40 rounded-md"
            @error="setAltImage(url)"
          />
          <div
            v-if="imageUrlsRequiringAltDisplay.includes(url)"
            class="w-full p-3 rounded-md bg-white text-sm font-normal text-yellow-300 flex"
          >
            <m-icon type="warning" size="6" class="mr-2" />
            <div class="text-gray-500 font-medium">
              この画像は削除されています
            </div>
          </div>
          <button
            v-if="status !== 'fulfilled'"
            type="button"
            class="absolute top-0 right-0 rounded-full w-10 h-10 bg-white bg-opacity-70 flex justify-center items-center"
            @click="deleteImage(url)"
          >
            <svg
              class="h-8 w-8 text-black"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </button>
        </div>
      </section>

      <section class="flex flex-col w-full mb-6">
        <h3 class="mb-2 font-medium">
          画像以外のファイル（音声/動画・PDFなど）
        </h3>
        <p
          v-if="fileDataList.length === 0"
          class="w-full p-3 rounded-md bg-white text-gray-500 text-sm"
        >
          画像以外のファイルはありません
        </p>
        <div
          v-for="(data, i) in fileDataList"
          :key="`file${i}`"
          class="relative my-1 p-1 flex-shrink-0 text-center bg-white"
        >
          <a
            :href="data.url"
            target="_blank"
            rel="noopener noreferrer"
            class="flex items-center text-sm font-medium text-primary-500"
          >
            <m-icon
              :type="data.contentIconType"
              width="0.7"
              size="8"
              class="mr-2"
            />
            <span class="text-black"
              >{{ data.contentTypeToDisplay }} ({{ data.sizeString }})</span
            ></a
          >
          <button
            v-if="status !== 'fulfilled'"
            type="button"
            class="absolute top-0 right-0 rounded-full w-10 h-10 bg-white bg-opacity-70 flex justify-center items-center"
            @click="deleteFreeFormatFile(data.url)"
          >
            <svg
              class="h-8 w-8 text-black"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </button>
        </div>
        <p class="mt-4 text-red-500 text-sm">
          ※ 動画などの容量の大きなファイルの提出には時間がかかる場合があります。
        </p>
      </section>

      <div
        v-if="
          (imageUrls.length > 0 || fileDataList.length > 0) &&
          (status === 'unsubmitted' || status === 'rejected_unsubmitted')
        "
        class="w-full p-3 mt-6 rounded-md bg-white text-sm font-normal"
      >
        <div class="flex items-center justify-center text-yellow-300 mb-4">
          <m-icon type="warning" size="6" class="mr-2" />
          <div class="text-gray-500 font-medium">
            先生に提出が終わったことを連絡する前に、もう一度次のことを確認しましょう！
          </div>
        </div>
        <p>
          ・画像が間違っていませんか？<br />
          ・文字はくっきり映っていますか？
        </p>
      </div>

      <div
        v-if="status === 'fulfilled'"
        class="w-full p-3 rounded-md bg-white text-sm font-normal text-yellow-300 flex"
      >
        <m-icon type="warning" size="6" class="mr-2" />
        <div class="text-gray-500 font-medium">
          先生が確認済のため更新できません。
        </div>
      </div>

      <nav class="fixed-nav py-3 flex flex-col items-center">
        <div class="max-w-sm">
          <div :class="isAndroid ? '' : 'flex'">
            <div :class="isAndroid ? 'flex' : 'w-1/2 flex items-stretch'">
              <label
                v-if="status !== 'fulfilled'"
                for="image"
                class="block w-full text-center py-2 rounded-md font-medium text-white leading-tight bg-primary-500 transition-all duration-300 hover:bg-primary-600"
              >
                画像を選択
                <input
                  id="image"
                  type="file"
                  accept="image/*"
                  name="image"
                  multiple
                  class="hidden"
                  @change="imageUpload"
                />
              </label>
              <input
                v-if="isAndroid && status !== 'fulfilled'"
                id="image-capture-input1"
                class="w-full customized-file-input ml-3 py-2 rounded-md font-medium text-white leading-tight bg-primary-500 transition-all duration-300 hover:bg-primary-600"
                type="file"
                accept="image/*"
                name="image-capture-input1"
                capture="environment"
                @change="imageUpload"
              />
            </div>
            <label
              v-if="status !== 'fulfilled'"
              for="any"
              class="block text-center py-2 rounded-md font-medium text-white leading-tight bg-primary-500 transition-all duration-300 hover:bg-primary-600"
              :class="isAndroid ? 'mt-3 w-full' : 'ml-3 w-1/2'"
            >
              画像以外を選択<br v-if="!isAndroid" /><span class="text-xs"
                >（音声・動画など）</span
              >
              <input
                id="any"
                type="file"
                accept="*"
                name="any"
                class="hidden"
                @change="freeFormatFileUpload"
              />
            </label>
          </div>
          <m-button class="w-full mt-3" primary @click="openCommentModal">
            {{ comment ? "先生へのコメントを編集" : "先生へのコメントを追加" }}
          </m-button>
          <m-button
            v-if="status !== 'fulfilled'"
            class="w-full mt-3"
            secondary
            :invalid="
              !validData ||
              status === 'submitted' ||
              status === 'rejected_submitted'
            "
            @click="completeSubmission"
          >
            {{
              status === "submitted" || status === "rejected_submitted"
                ? "提出完了を登録済"
                : "提出完了の登録"
            }}
          </m-button>
          <m-button
            v-if="hasPreviousPage"
            alternative
            class="w-full mt-3"
            @click="goBack"
          >
            戻る
          </m-button>
        </div>
      </nav>
      <m-text-input-modal
        v-if="showCommentModal"
        :defaultValue="comment"
        title="先生へのコメントを入力"
        @save="saveComment"
        @close="showCommentModal = false"
      ></m-text-input-modal>
    </main>
  </div>
</template>

<script lang="ts">
import firebase from "firebase/app";
import "firebase/storage";
import MButton from "@/components/form/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MTextInputModal from "@/components/MTextInputModal.vue";
import {
  getSubmissionOfStudent,
  putSubmission,
  createNotificationForTutors,
  complementSubmissions
} from "@/api/submission";
import { postFile, deleteFile, postFileAsUploadTask } from "@/api/storage";
import { Student } from "@/entities/student";
import {
  safeFileTypes,
  SubmissionStatus,
  getValuesFromFileMetadata,
  ComplementedSubmission
} from "@/entities/submission";
import { RelatedLink } from "@/entities/submission_master";
import store, { awaitStudentLoaded } from "@/store";
import {
  convertToDateFromUnixtime,
  convertToTimeFromUnixtime
} from "@/utils/date";
import { Options, Vue } from "vue-class-component";
import dayjs from "dayjs";
import Compressor from "compressorjs";
import mime from "mime";

@Options({
  components: {
    MButton,
    MIcon,
    MTextInputModal
  }
})
export default class SubmissionView extends Vue {
  checkedAtText = "";
  submittedAtText = "";
  dataMap: ComplementedSubmission | null = null;
  imageUrls: string[] = [];
  imageUrlsRequiringAltDisplay: string[] = []; // Storage上に画像がない場合 URL をこちらに格納し、代替表示を行う
  fileDataList: {
    url: string;
    contentTypeToDisplay: string;
    contentIconType: string;
    sizeString: string;
  }[] = [];
  status: SubmissionStatus = "unsubmitted";
  closedAtText = "";
  submissionName = "";
  submissionLabel = "";
  description = "";
  comment = "";
  relatedLinks: RelatedLink[] = [];
  student: Student | null = null;
  showCommentModal = false;

  get isAndroid() {
    // 撮影ボタンを Android のみ表示する
    return store.state.isAndroid;
  }

  get validData() {
    return this.imageUrls.length > 0 || this.fileDataList.length > 0;
  }

  get hasPreviousPage() {
    // 通知メッセージから直接この画面を開いた場合、戻り先のページがない
    return history.length > 1;
  }

  async imageUpload(event: Event) {
    if (
      !(event.target instanceof HTMLInputElement) ||
      !event.target.files ||
      event.target.files.length === 0 ||
      !store.state.schoolDocId ||
      !store.state.classroomDocId ||
      !this.student ||
      !this.dataMap
    ) {
      return;
    }
    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "画像をアップロード中...");

    const submittedAt = dayjs().format("YYYY-MM-DD-HH-mm-ss");
    const promises: Promise<string>[] = [];

    for (let i = 0, l = event.target.files.length; i < l; i++) {
      const file = event.target.files[i];
      if (!safeFileTypes.includes(file.type)) {
        store.commit("SET_LOADING", false);
        alert(`次の種類のファイルは提出できません。\n【${file.type}】`);
        continue;
      }
      const directory = `submissions/schools/${store.state.schoolDocId}/classrooms/${store.state.classroomDocId}/students/${this.student.ref.id}/submissions/${this.dataMap.ref.id}/`;
      const fileName = `${this.dataMap.submissionMasterRef?.id ?? ""}_${
        this.dataMap.ref.id
      }_${submittedAt}_${i}`;

      // 軽量化してアップロードする
      promises.push(
        new Promise((resolve, reject) => {
          new Compressor(file, {
            quality: 0.5,
            convertSize: 300 * 1000, // 300KB 以上の png は jpg に変換する
            success(result) {
              const fileToUpload = result as File;
              // jpg に変換されている可能性があるので、ここで拡張子を決定する
              const ext = "." + fileToUpload.name.split(".").pop();

              postFile(fileToUpload, {
                path: directory + fileName + ext
              })
                .then(({ downloadURL }: { downloadURL: string }) => {
                  resolve(downloadURL);
                })
                .catch(reject);
            },
            error: reject
          });
        })
      );
    }
    await Promise.all(promises)
      .then(newUrls => {
        if (!this.dataMap) throw new Error("非同期処理中の例外的なエラー"); // 通常考えられない、ビルドを通すために必要
        putSubmission(this.dataMap.ref, {
          imageUrls: [...this.imageUrls, ...newUrls]
        });
        this.imageUrls = [...this.imageUrls, ...newUrls];
      })
      .catch(e => {
        alert(`ファイルの提出時にエラーが発生しました。\n\n${e.message}`);
      });
    store.commit("SET_LOADING", false);
  }

  async freeFormatFileUpload(event: Event) {
    if (
      !(event.target instanceof HTMLInputElement) ||
      !event.target.files ||
      event.target.files.length === 0 ||
      !store.state.schoolDocId ||
      !store.state.classroomDocId ||
      !this.student ||
      !this.dataMap
    ) {
      return;
    }
    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "ファイルをアップロード中...");

    const submittedAt = dayjs().format("YYYY-MM-DD-HH-mm-ss");

    const file = event.target.files[0];
    const directory = `submissions/schools/${store.state.schoolDocId}/classrooms/${store.state.classroomDocId}/students/${this.student.ref.id}/submissions/${this.dataMap.ref.id}/`;
    const fileName = `${this.dataMap.submissionMasterRef?.id}_${this.dataMap.ref.id}_file_${submittedAt}`;

    new Promise<firebase.storage.Reference>((resolve, reject) => {
      const ext = mime.getExtension(file.type)
        ? `.${mime.getExtension(file.type)}`
        : "";
      postFileAsUploadTask(file, {
        path: `${directory}${fileName}${ext}`,
        onStateChanged: summary => {
          store.commit(
            "SET_LOAD_TEXT",
            `アップロード中 : ${summary.transferredPercentage} / 100 % 完了`
          );
        },
        onError: reject,
        onComplete: resolve
      });
    })
      .then(async fileRef => {
        const downloadURL = await fileRef.getDownloadURL();
        const { contentType, size } = await fileRef.getMetadata();
        if (!this.dataMap) throw new Error("非同期処理中の例外的なエラー"); // 通常考えられない、ビルドを通すために必要
        putSubmission(this.dataMap.ref, {
          fileUrls: [...this.fileDataList.map(data => data.url), downloadURL]
        });
        const values = getValuesFromFileMetadata(size, contentType);
        this.fileDataList = [
          ...this.fileDataList,
          {
            url: downloadURL,
            ...values,
            contentTypeToDisplay:
              values.contentIconType !== "file"
                ? values.contentTypeToDisplay
                : `${mime.getExtension(contentType)}ファイル` ??
                  values.contentTypeToDisplay
          }
        ];
        store.commit("SET_LOADING", false);
        store.commit("SET_LOAD_TEXT", "");
      })
      .catch(e => {
        alert(`ファイルの提出時にエラーが発生しました。\n\n${e.message}`);
        store.commit("SET_LOADING", false);
        store.commit("SET_LOAD_TEXT", "");
      });
  }

  async deleteImage(url: string) {
    if (!this.dataMap) return;
    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "画像を削除中...");
    const imageRef = firebase.storage().refFromURL(url);
    try {
      const newUrls = this.imageUrls.filter(u => u !== url);
      putSubmission(this.dataMap.ref, {
        imageUrls: newUrls
      });
      this.imageUrls = newUrls;
      if (!this.imageUrlsRequiringAltDisplay.includes(url))
        await deleteFile(imageRef);
    } catch (e) {
      alert(`画像の削除に失敗しました。\n\n${e.message}`);
    } finally {
      store.commit("SET_LOADING", false);
    }
  }

  async deleteFreeFormatFile(url: string) {
    if (!this.dataMap) return;
    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "ファイルを削除中...");
    const fileRef = firebase.storage().refFromURL(url);
    try {
      const newDataList = this.fileDataList.filter(data => data.url !== url);
      putSubmission(this.dataMap.ref, {
        fileUrls: [...newDataList.map(data => data.url)]
      });
      this.fileDataList = newDataList;
      await deleteFile(fileRef);
    } catch (e) {
      alert(`ファイルの削除に失敗しました。\n\n${e.message}`);
    } finally {
      store.commit("SET_LOADING", false);
    }
  }

  setAltImage(url: string) {
    this.imageUrlsRequiringAltDisplay = [
      url,
      ...this.imageUrlsRequiringAltDisplay
    ];
  }

  async completeSubmission() {
    if (
      !this.validData ||
      !this.student ||
      !this.dataMap ||
      this.status === "submitted" ||
      this.status === "rejected_submitted" ||
      this.status === "fulfilled"
    )
      return;
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "先生にメッセージを送信中...");
      await putSubmission(this.dataMap.ref, {
        status:
          this.status === "unsubmitted" ? "submitted" : "rejected_submitted",
        submittedAt: dayjs().unix()
      });
      await createNotificationForTutors(this.student.ref, {
        title: `${this.student.data.name}が${this.submissionName}を${
          this.status === "unsubmitted" ? "" : "再"
        }提出しました`,
        content: this.comment ? `【先生へのコメント】\n\n${this.comment}` : "",
        submissionDocId: this.dataMap.ref.id
      });
      alert(
        `🙆‍♂️ ${this.submissionName}を提出したことを先生に報告しました📣 おつかれさま😀`
      );
      this.$router.replace({
        path: "/submission/"
      });
    } catch (e) {
      alert(`提出完了の処理中にエラーが発生しました。`);
    } finally {
      store.commit("SET_LOADING", false);
    }
  }

  goBack() {
    this.$router.go(-1);
  }

  openCommentModal() {
    this.showCommentModal = true;
  }

  updateComment(next: string) {
    this.comment = next;
  }

  async saveComment(next: string) {
    if (!this.dataMap) return;
    // 期限なしの場合、post の一種類として先生へのコメントを考える
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "コメントを保存中...");
      await putSubmission(this.dataMap.ref, {
        comment: next
      });
      this.comment = next;
    } catch (e) {
      alert(`先生へのコメントが正しく保存できませんでした。`);
    } finally {
      store.commit("SET_LOADING", false);
    }
  }

  async created() {
    this.student = await awaitStudentLoaded(store);
    const submissionId = this.$route.params.submissionId as string;
    if (!submissionId) {
      return;
    }
    const submissions = await getSubmissionOfStudent(this.student.ref, {
      submissionId
    });
    if (!submissions?.[0]) return;
    const complemented = (await complementSubmissions(
      submissions
    )) as ComplementedSubmission[];
    this.dataMap = complemented[0];
    if (!this.dataMap?.submissionMasterData) return;

    this.imageUrls = this.dataMap.data.imageUrls;
    this.comment = this.dataMap.data.comment ?? "";
    this.fileDataList = this.dataMap.data.fileDataList;
    this.status = this.dataMap.data.status;
    this.checkedAtText =
      this.dataMap.data.checkedAt > 0
        ? convertToDateFromUnixtime(this.dataMap.data.checkedAt)
        : "";
    this.submittedAtText =
      this.dataMap.data.submittedAt > 0
        ? convertToDateFromUnixtime(this.dataMap.data.submittedAt)
        : "";
    this.closedAtText =
      this.dataMap.data.closedAt > 0
        ? `${convertToDateFromUnixtime(
            this.dataMap.data.closedAt
          )} ${convertToTimeFromUnixtime(this.dataMap.data.closedAt)}`
        : "";
    this.submissionName = this.dataMap.submissionMasterData.name;
    this.submissionLabel = this.dataMap.submissionMasterData.label;
    this.description = this.dataMap.submissionMasterData.description ?? "";
    this.relatedLinks = this.dataMap.submissionMasterData.relatedLinks ?? [];
  }
}
</script>

<style lang="scss" scoped>
.customized-file-input {
  text-indent: -9999px;
  font-size: 12px;
  position: relative;
  &:before {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 100%;
    content: "画像を撮影";
    font-size: 16px;
    text-indent: 0;
    text-align: center;
  }
}
.fixed-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
}
</style>
